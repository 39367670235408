import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";

export const reminderLogConstants=(handleResetFilter: any, isConsoleReminderLogs: boolean = false)=>{
    const columns=[
        {
          headerName: "User ID",
          field: "username",
          sortable: true,
          filter: { show: true },
          width: isConsoleReminderLogs ?"22rem":"16rem",
          //minWidth: "10rem"
        },
        {
          headerName: "Broadcom ERP Account",
          field: "erpAccountName",
          sortable: true,
          filter: { show: true },
          width: "11rem",
        },
        {
          headerName: "Reminder Notice in Days",
          field: "reminderInDays",
          sortable: false,
          filter: { show: true },
          width: "10rem",
        },
        {
          headerName: "Reminder Type",
          field: "isReportReminder",
          sortable: false,
          filter: { show: true },
          width: "8rem",
        },
        {
          headerName: "Serial Number",
          field: "serialNumber",
          sortable: true,
          filter: { show: true },
          width: isConsoleReminderLogs ? "8rem":"6rem",
        },
        {
          headerName: "Reminder Date",
          field: "lastRemainderDate",
          sortable: true,
          type: "date",
          filter: { show: true, type: "date", range: true },
          cellRenderer: (
            <TableCellRenderer fieldName="lastRemainderDate" type="date" skipTimezone="true" />
          ),
          width: isConsoleReminderLogs ? "8rem":"6rem",
        },
        {
          headerName: "Reporting Start Date",
          field: "reportingPeriodStartDateEpoch",
          sortable: true,
          type: "date",
          filter: { show: true, type: "date", range: true },
          cellRenderer: (
            <TableCellRenderer
              fieldName="reportingPeriodStartDateEpoch"
              type="date"
              skipTimezone="true"
            />
          ),
          width: isConsoleReminderLogs ? "8rem":"6rem",
        },
        {
          headerName: "Reporting End Date",
          field: "reportingPeriodEndDateEpoch",
          sortable: true,
          type: "date",
          filter: { show: true, type: "date", range: true },
          cellRenderer: (
            <TableCellRenderer
              fieldName="reportingPeriodEndDateEpoch"
              type="date"
              skipTimezone="true"
            />
          ),
          width: isConsoleReminderLogs ? "8rem":"6rem",
        },
    
        {
          headerName: "Action",
          field: "action",
          filter: { show: true },
          filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
          width: isConsoleReminderLogs ? "12rem":"10rem"
        },
      ];

    if (!isConsoleReminderLogs) {
        columns.splice(2, 0, {
          headerName: "Site Id",
          field: "supportSiteId",
          sortable: true,
          filter: { show: true },
          width: "8rem",
        });
    }
    return columns;
} 