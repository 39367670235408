import ClayCard from "@clayui/card";
import {addNewAdminUser, getEditUserDetail, getUpdateUserFlag, updateAdminUserDetails} from "../../../../../store/console/scrtUserManagement/slice";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, PageTitle } from "@common-components";
import { Formik } from "formik";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";
import { IGetUserDetails } from "utils/interfaces/scrtUserManagement";

const UserDetail=(props:any)=>{
    const { showUserTable,userRoleList } = props;
    const dispatch=useDispatch();
    const formref=useRef(null)
    const userDetail:IGetUserDetails= useSelector(getEditUserDetail);
    const updateUserFlag:any=useSelector(getUpdateUserFlag)
    const regex = {
        alphaNumeric: /^[aA-zZ0-9.,\s]+$/,
        alphabets: /^[aA-zZ\s]+$/,
      };
    
    const roleList=[
        {label: "--Select--",value:""},
        {label: "Broadcom SCRT Admin",value:"Broadcom SCRT Admin"},
        {label: "Broadcom Audit/Compliance",value:"Broadcom Audit/Compliance"},
        {label: "GTO Support",value:"GTO Support"},
        {label: "MSD Support",value:"MSD Support"},
    ]

    const accessList=[
        { label:"Yes",value:"Yes" },
        { label:"No",value:"No" },
    ]

    const initialValues:IGetUserDetails = {
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        userId: userDetail.userId,
        role: userDetail.role,
        access: userDetail.access
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
          .required("First Name is required")
          .min(2, "A minimum of 2 characters is required")
          .max(40, "Maximum allowed characters is 40")
          .matches(regex.alphabets, "Numbers & special characters are not allowed"),
        lastName: Yup.string()
          .required("Last Name is required")
          .min(2, "A minimum of 2 characters is required")
          .max(40, "Maximum allowed characters is 40")
          .matches(regex.alphabets, "Numbers & special characters are not allowed"),
        userId: Yup.string().email("Email is invalid").required("Email is required"), 
        role:Yup.string()
                .required("Role is required")
                .notOneOf(["-Select-"], "Role is required"),
      });

    useEffect(()=>{
        updateUserFlag && showUserTable(userDetail.userId)
    },[updateUserFlag])// eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit=(data:IGetUserDetails)=>{
        const obj={
            access: data.access === "Yes" ? true : false,
            firstName: data.firstName,
            lastName: data.lastName,
            role: data.role,
            userId:data.userId
        }
        userDetail.userId ? 
        dispatch(updateAdminUserDetails(obj))
        : dispatch(addNewAdminUser(obj))
    }

    return(
        <>
            
                <ClayCard.Body className="p-4">
                    <ClayLayout.Row className="pb-3">
                    <PageTitle title={userDetail.userId ? "Edit User Detail" : "Create User"} />
                    </ClayLayout.Row>
                    
                    <Formik
                        enableReinitialize={true}
                        destroyOnUnmount={false}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        innerRef={formref}
                    >
                        {(formik) => (
                        <ClayForm noValidate onSubmit={formik.handleSubmit}>
                            <ClayLayout.Row>
                                <ClayLayout.Col size={6}>
                                    <FormControl
                                        control="text"
                                        name="firstName"
                                        label="First Name"
                                        autoComplete={"off"}
                                        required={true}
                                    />
                                </ClayLayout.Col>
                                <ClayLayout.Col size={6}>
                                    <FormControl
                                        control="text"
                                        name="lastName"
                                        label="Last Name"
                                        autoComplete={"off"}
                                        required={true}
                                    />
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                            <ClayLayout.Row>
                                <ClayLayout.Col size={6}>
                                    <FormControl
                                        control="text"
                                        name="userId"
                                        label="Email"
                                        autoComplete={"off"}
                                        required={true}
                                    />
                                </ClayLayout.Col>
                                <ClayLayout.Col size={6}>
                                    <FormControl
                                        control="select"
                                        name="role"
                                        label="Role"
                                        autoComplete={"off"}
                                        options={userRoleList}
                                        required={true}
                                    />
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                            { userDetail.userId &&
                                <ClayLayout.Row>
                                    <ClayLayout.Col size={6}>
                                        <FormControl
                                            control="select"
                                            name="access"
                                            label="Access"
                                            autoComplete={"off"}
                                            options={accessList}
                                            required={false}
                                        />
                                </ClayLayout.Col>
                                </ClayLayout.Row>
                            }
                            <ClayLayout.Row justify="end" className="align-items-center">
                                <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
                                    <ClayButton
                                        className="ml-3"
                                        displayType="secondary"
                                        onClick={()=>showUserTable("don't reset")}
                                    >
                                        Back
                                    </ClayButton>
                                    <ClayButton
                                        className="ml-3"
                                        type="submit"
                                        displayType="primary"
                                    >
                                        {userDetail.userId ? "Update" : "Create"}
                                    </ClayButton>
                                </ClayLayout.Col>
                            </ClayLayout.Row>
                        </ClayForm>
                        )}
                    </Formik>
                    
                    
                </ClayCard.Body>
        </>
    )
}

export default UserDetail;