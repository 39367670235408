import moment from "moment";
import momentTimezone from "moment-timezone";

import {
  LOCAL_ENV,
  OKTATOKENSTORAGEKEY,
  PROD_ENV,
  RICH_TEXT_INDENT,
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_USER,
  ROUTE_PATHS,
  SCRTLOCALKEY,
  USER_FORMAT,
  USER_TZ_FORMAT,
  UTC_TZ_FORMAT,
  VERIFY_ENV,
} from "./constants";

//Check if role is user
export const isUser = (role: string) => !!role && role === ROLE_USER;

//Check if role is Admin
export const isAdmin = (role: string) => !!role && role === ROLE_ADMIN;

//check if role is Super Admin
export const isSuperAdmin = (role: string) => !!role && role === ROLE_SUPER_ADMIN;

//format string replaces _ with space
export const formatPreviewObject = (item: string) => {
  if (!item) {
    return item;
  }
  return item.replaceAll("_", " ").toUpperCase();
};

export const formatFilterStartEndDate = (item: string) => {
  return item.replace(" - ", "_");
};

//To guess browser timezone
export const browserTimezone = momentTimezone.tz.guess();

//Avinash,Vunnava, 22/4/2022 Timezone Implementation
//To convert UTC time to user preferred format
//@Input: date: 2021-10-14T06:59:30.223+0000 timezone: "Asia/Calcutta"
//@Output: Oct-14-2021 12:10 IST
export const getUserTimeFromUTC = (date: string | Date | any, timezone?: string) => {
  if (!date) {
    return date;
  }
  //If Timezone is not passed consider Browser Timezone
  const zone = !!timezone ? timezone : browserTimezone;
  return momentTimezone(date).tz(zone).format(USER_TZ_FORMAT);
};

//Avinash,Vunnava, 22/4/2022 Timezone Implementation
//To convert user timezone to UTC format
//@Input date: Oct-14-2021 12:10, timezone: "Asia/Calcutta", inputTimeFormat: "MMM-dd-yyyy HH:MM"
//@Output 2021-10-14T06:59:30.223+0000
export const convertToUTC = (date: string, inputTimeFormat: string, timezone?: string) => {
  if (!date || !!inputTimeFormat) {
    return date;
  }
  const zone = !!timezone ? timezone : browserTimezone;
  return momentTimezone(date, inputTimeFormat, zone).utc().format(UTC_TZ_FORMAT);
};

//To convert time to user format
//@Input: date: 2021-10-14T06:59:30.223+0000
//@Output: Oct-14-2021 12:10 IST
export const getUserTime = (date: string | Date | any, skipTimezone: string = "false") => {
  if (!date) {
    return date;
  }
  return skipTimezone === "true"
    ? moment(date).format(USER_FORMAT)
    : moment(date).format(USER_TZ_FORMAT);
};

//Checks for location pathname is ECX redirect
export const isECXRedirect = (path: string) => path === ROUTE_PATHS.ECX_REDIRECT;

//Checks for location pathname is OKTA redirect
export const isOktaRedirect = (path: string) => path === ROUTE_PATHS.OKTA_REDIRECT;

//Check for location pathname is Logout
export const isLogoutRedirect = (path: string) => path === ROUTE_PATHS.LOGOUT;

export const isRepoLogsRedirect = (path: string) => path === ROUTE_PATHS.REPO_LOGS;

//Check if pathname is noAccess
export const isNoAccessRedirect = (path: string) => path === ROUTE_PATHS.NO_ACCESS;

export const isLoginRedirect = (path: string) => path === ROUTE_PATHS.OKTA_LOGIN;

export function GET_USER_EMAIL() {
  const oktaToken = JSON.parse(localStorage.getItem(OKTATOKENSTORAGEKEY) || "{}");
  return oktaToken?.idToken?.claims?.email || "";
}

export function GET_USER_NAME_OKTA() {
  const user = getCookie("user");
  if (user !== "") {
    try {
      const cookieUser = JSON.parse(user);
      const username = cookieUser?.firstName+" "+cookieUser?.lastName;
      return cookieUser === undefined ? "" : username;
    } catch (e) {
      console.log(e);
      return "";
    }
  }
  return "";
}

export function GET_USER_EMAIL_AUTHHUB_LOWERCASE() {
  const user = getCookie("user");
  if (user !== "") {
    try {
      let validCookie = JSON.parse(user)?.email;
      return validCookie === undefined ? "" : validCookie.toLowerCase();
    } catch (e) {
      console.log(e);
      return "";
    }
  }
  return "";
}

export function GET_USER_NAME() {
  const scrtToken = JSON.parse(localStorage.getItem(SCRTLOCALKEY) || "{}");
  return scrtToken?.name || "";
}

export function GET_USER_ROLE() {
  const scrtToken = JSON.parse(localStorage.getItem(SCRTLOCALKEY) || "{}");
  return scrtToken?.role || ROLE_USER;
}

export function isMCLUser() {
  const scrtToken = JSON.parse(localStorage.getItem(SCRTLOCALKEY) || "{}");
  return scrtToken?.mclUser || false;
}

export function isConsoleAdmin() {
  const scrtToken = JSON.parse(localStorage.getItem(SCRTLOCALKEY) || "{}");
  return scrtToken?.consoleAdmin || false;
}

export function getAuthToken() {
  const oktaStorage = localStorage.getItem(OKTATOKENSTORAGEKEY) || "{}";
  return JSON.parse(oktaStorage)?.accessToken?.accessToken || "";
}

export function GET_MONTH_YEAR_FROM_DATE(date: string) {
  if (!date) {
    return date;
  }
  const newDate = date.split("-");
  return `${newDate[0]} ${newDate[1].substr(-2)}`;
}

export function GET_MONTH_YEAR_AS_OBJ(date: string) {
  if (!date) {
    return date;
  }
  const newDate = date.split("-");
  return { year: newDate[1], month: newDate[0] };
}

export function formatRichText(data: string) {
  let formatData = data;
  Object.keys(RICH_TEXT_INDENT).forEach((key: string) => {
    const regexP = new RegExp(`<p class="${key}">`, "gi");
    const regexLI = new RegExp(`<li class="${key}">`, "gi");
    const value = RICH_TEXT_INDENT[key];
    formatData = formatData.replaceAll(regexP, `<p style="padding-left:${value};">`);
    formatData = formatData.replaceAll(regexLI, `<li style="margin-left:${value};">`);
  });
  return formatData;
}

export function capitalizeFirstLetter(word: string) {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function tokenize(word: string) {
  return !!word ? word.replaceAll("_", " ") : word;
}

export const isVerifyProdEnv = (env: string = LOCAL_ENV) => {
  return [VERIFY_ENV, PROD_ENV].includes(env);
};

export const getCookie = (key: string): string => {
  var result = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${key}=`))
    ?.split("=")[1];
  return result != null ? decodeURIComponent(result) : "";
};

export function GET_USER_EMAIL_AUTHHUB() {
  const user = getCookie("user");
  if (user !== "") {
    try {
      let validCookie = JSON.parse(user)?.email;
      return validCookie === undefined ? "" : validCookie;
    } catch (e) {
      console.log(e);
      return "";
    }
  }
  return "";
}

export const deleteCookie = (name: string) => {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
