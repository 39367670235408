import { DropdownFilter } from "@common-components";
import { ActionComponent } from "@components/ActionComponent/ActionComponent";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DONWLOAD_SOURCE } from "@constants";

export let REPO_LOGS_OPTIONS = [
  { label: "All Status", value: "" },
  { label: "Success", value: "success" },
  { label: "Failure", value: "failure" },
];

export const REPORT_TYPE_OPTIONS = [
  { label: "All Report Types", value: "" },
  { label: "IBM", value: "IBM" },
  { label: "ISV", value: "ISV" },
];

export const SOURCE_TYPE_OPTIONS = [
  { label: "All Sources", value: "" },
  { label: "Website", value: "website" },
  { label: "MWP Mailbox", value: "mwp_mailbox" },
  { label: "SCRT Mailbox", value: "scrt_mailbox" },
];

export const repoLogColumns = (handleResetFilter: any, isAuditLogs: boolean = false) => {
  const columns = [
    {
      headerName: "Date",
      field: "date",
      sortable: true,
      width: "6rem",
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="date" type="date" />,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={isAuditLogs ? REPO_LOGS_OPTIONS : [...REPO_LOGS_OPTIONS,{ label: "Warning", value: "warning" }]} />,
      cellRenderer: <TableCellRenderer fieldName="status" type="capitalize" />,
      width:"8rem",
    },
    {
      headerName: "Report Type",
      field: "reportType",
      sortable: true,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={REPORT_TYPE_OPTIONS} />,
      width:"10rem",
    },
    {
      headerName: "Source",
      field: "source",
      sortable: true,
      filter: { show: true },
      filterRenderer: isAuditLogs ? <></> : <DropdownFilter options={SOURCE_TYPE_OPTIONS} />,
      cellRenderer: <TableCellRenderer fieldName="source" type="tokenize" />,
      width:"10rem",
    },
    {
      headerName: "User ID",
      field: "username",
      sortable: true,
      filter: { show: true },
      width: "12rem",
    },
    {
      headerName: "Broadcom ERP Account",
      field: "erpcustomername",
      sortable: true,
      filter: { show: true },
      width: "8rem",
    },
    {
      headerName: "File Name",
      field: "filename",
      width: isAuditLogs?"28rem":"14rem",
      sortable: true,
      filter: { show: true },
    },
    {
      headerName: "Concatenated",
      field: "concatenate",
      sortable: true,
      filter: { show: true },
      width: "6rem"
    },
    {
      headerName: "Serial Number",
      field: "serialnumber",
      sortable: true,
      filter: { show: true },
      width: isAuditLogs?"6rem":"8rem",
    },
    {
      headerName: "Reporting Period",
      field: "reportingPeriod",
      sortable: true,
      width: "10rem",
      filter: { show: true },
    },
    {
      headerName: "Message",
      field: "errorMessage",
      sortable: true,
      width: "15rem",//"6rem",
      filter: { show: true },
      cellRenderer: <TableCellRenderer fieldName="errorMessage" type="html" />,
    },
    {
      headerName: "Action",
      field: "action",
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
      cellRenderer: (
        <ActionComponent
          hidePreview={true}
          showDelete={false}
          disabledDownload={false}
          source={DONWLOAD_SOURCE.Repository}
        ></ActionComponent>
      ),
      width: "10rem"
    },
  ];
  if (isAuditLogs) {
    columns.splice(4, 0, {
      headerName: "Support Site ID",
      field: "supportSiteId",
      sortable: true,
      filter: { show: true },
      width: "10rem",
    });
  }
  return columns;
};

export const timeStampFormat=(dateVal:any)=>{
  let dateStr=new Date(dateVal)
  let finalStr=""
  if(dateVal!==""){
    let year:any=dateStr.getFullYear()
    let month:any=''+(dateStr.getMonth()+1)
    let day:any=''+dateStr.getDate()

    let hours:any = dateStr.getHours();
    let minutes:any = dateStr.getMinutes();

    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    if(month.length<2){
      month='0'+month
    }
    if(day.length<2){
      day='0'+day
    }
    finalStr=`${year}-${month}-${day} ${hours}:${minutes} ${newformat}`
  }
  return finalStr
}

export const dateFormat=(dateVal:any)=>{
  let dateStr=new Date(dateVal)
  let finalStr=""
  if(dateVal!==""){
    let year:any=dateStr.getFullYear()
    let month:any=''+(dateStr.getMonth()+1)
    let day:any=''+dateStr.getDate()

    if(month.length<2){
      month='0'+month
    }
    if(day.length<2){
      day='0'+day
    }
    finalStr=`${year}-${month}-${day}`
  }
  return finalStr
}
const convertToCSV = (objArray: any) => {
  const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  //Since reporting period is common for all rows adding as first row
  //str += `Reporting Period,${reportingPeriod}\r\n`;

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

//Export JSON to CSV and download
export const exportCSVFile = (items: any, fileTitle: string) => {
  
  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject);
  const exportedFilenmae = fileTitle + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
