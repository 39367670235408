import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { Table, TableFilterCheckbox } from "@common-components";
import TableCheckBox from "@components/checkbox/TableCheckbox";
import "./scrtReportManagement.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClayButton from "@clayui/button";
import { IReportManagementDetails } from "utils/interfaces/scrtReportManagement";
import { deleteReports, getSCRTRepoList, getSuccessFullDelete, loadSCRTReportManagementDetails } from "@store/console/scrtReportManagement/slice";
import { ClayInput, ClayToggle } from "@clayui/form";
import { MultiDeleteModal } from "@components/screens/repository/MultiDeleteModal";

const SCRTReportManagement = ()=>{

    const dispatch = useDispatch()

    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [downloadReportIdObj, setDownloadReportIdObj] = useState<any>({});
    const [allCheckBoxSelected, setAllCheckBoxSelected] = useState<boolean>(false);
    const [data, setData] = useState<IReportManagementDetails[]>([])
    const [searchedSupportSite,setSearchedSupportSite] = useState<string>("")
    const [isSearchGoingOn, setIsSearchGoingOn] = useState<boolean>(false);
    const [searchedReportId,setSearchedReportId] = useState<string>("")
    const [hardDelete,setHardDelete] = useState<boolean>(false)
    const [showTable,setShowTable] = useState<boolean>(false)
    const [deleteModalFlag,setDeleteModalFlag] = useState<boolean>(false)
    const [modalType , setModalType]= useState<string>("")

    const rowData:IReportManagementDetails[]=useSelector(getSCRTRepoList)
    const successFullDelete:any=useSelector(getSuccessFullDelete)

    const toggleChange=(val:boolean)=>{
      setHardDelete(val);
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      setDownloadReportIdObj({});
      let obj={
        siteId: searchedSupportSite,
        reportId: searchedReportId,
        deleteFlag: val
      }
      dispatch(loadSCRTReportManagementDetails(obj))
    }  
    useEffect(()=>{
      if(successFullDelete){
        let obj={
          siteId: searchedSupportSite,
          reportId: searchedReportId,
          deleteFlag: hardDelete
        }
        dispatch(loadSCRTReportManagementDetails(obj))
      }
    },[successFullDelete])  // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
      const delayDebounceFn = setTimeout(() => {
        if (isSearchGoingOn) {
          setIsSearchGoingOn(false);
          
          if(searchedReportId.length===0 && searchedSupportSite.length===0){
            setShowTable(false)
            setIsSelectAll(false);
            setAllCheckBoxSelected(false);
            setDownloadReportIdObj({});
            setHardDelete(false)
          }else{
            setShowTable(true)
            let obj={
              siteId: searchedSupportSite,
              reportId: searchedReportId,
              deleteFlag: hardDelete
            }
            dispatch(loadSCRTReportManagementDetails(obj))
          }
        }
      }, 750);
      return () => {
          clearTimeout(delayDebounceFn);
      };
    },[searchedReportId,searchedSupportSite]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleReportSearch=(val:string)=>{
      setSearchedReportId(val)
    }

    const handleSupportSearch=(val:string)=>{
      setSearchedSupportSite(val)
    }

    useEffect(() => {
        let count = 0;
        const newData:IReportManagementDetails[] =
          (rowData && rowData.length &&
            rowData.map((row:IReportManagementDetails) => {
              !!downloadReportIdObj[row.reportId] && (count += 1);
              return {
                ...row,
                isChecked: !!downloadReportIdObj[row.reportId],
              };
            })) ||
          [];

          setData(newData);
          setIsSelectAll(newData.length > 0 && newData.length === count);
      }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheckboxChange = (index: number, value: boolean) => {
        const checkedData: any = [...data];
        const newDownloadReportIdObj: any = { ...downloadReportIdObj };
        checkedData[index].isChecked = value;
        if (value) {
          newDownloadReportIdObj[checkedData[index].reportId] = checkedData[index].reportId;
        } else {
          delete newDownloadReportIdObj[checkedData[index].reportId];
        }
        const len = checkedData.filter((i: any) => {
          return i.isChecked;
        }).length;
        
        setIsSelectAll(len === checkedData.length);
        setAllCheckBoxSelected(Object.values(newDownloadReportIdObj).length > 1);
        setData(checkedData);
        setDownloadReportIdObj(newDownloadReportIdObj);
    };
    
    const handleAllCheckboxChange = (value: boolean) => {
      const checkedData:IReportManagementDetails[] = [...data];
      const newDownloadReportIdObj: any = { ...downloadReportIdObj };
      checkedData.map((i: IReportManagementDetails) => {
        i.isChecked = value;
        if (value) {
          newDownloadReportIdObj[i.reportId] = i.reportId;
        } else {
          delete newDownloadReportIdObj[i.reportId];
        }
        return i;
      });
      setAllCheckBoxSelected(value);
      setIsSelectAll(value);
      setData(checkedData);
      setDownloadReportIdObj(newDownloadReportIdObj);
    };

    const handleDelete=(list:any)=>{
      document.body.style.overflow = "visible";
      
      setDeleteModalFlag(false)
      let obj={
        reportIds: list,
        hardDelete: modalType === "Hard Delete" ? true : false
      }
      dispatch(deleteReports(obj))
      setModalType("")
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      setDownloadReportIdObj({});
    }
    const REPORT_COLUMNS = [
        {
          headerName: "",
          field: "",
          sortable: false,
          cellRenderer: <TableCheckBox handleCheckboxChange={handleCheckboxChange}></TableCheckBox>,
          filter: { show: data.length > 0, type: "selectCheckbox" },
          filterRenderer: (
            <TableFilterCheckbox
              handleAllCheckboxChange={handleAllCheckboxChange}
              isSelectAll={isSelectAll}
            />
          ),
          width:"6rem"
        },
        {
          headerName: "Report Id",
          field: "reportId",
          sortable: false,
          width:"6rem"
        },{
            headerName: "Upload Date",
            field: "uploadDate",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Uploaded By",
            field: "uploadedBy",
            sortable: false,
            width:"6rem"
          },{
            headerName: "SCRT Customer Number",
            field: "customerNumber",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Support Site Id",
            field: "supportSiteId",
            sortable: false,
            width:"6rem"
          },{
            headerName: "ERP Cust Account Number",
            field: "customerNumber",
            sortable: false,
            width:"6rem"
          },{
            headerName: "ERP Cust Account Name",
            field: "customerName",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Report Period Start Date",
            field: "reportingPeriodStartDateEpoch",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Machine Serial Number",
            field: "machineSerialNumber",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Machine Type",
            field: "machineType",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Machine Model",
            field: "machineModel",
            sortable: false,
            width:"6rem"
          },{
            headerName: "File Name",
            field: "fileName",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Deleted",
            field: "deleted",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Report Type",
            field: "reportType",
            sortable: false,
            width:"6rem"
          },{
            headerName: "Source",
            field: "source",
            sortable: false,
            width:"6rem"
          },
        
    ];
  
    return (
        <div>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayCard className="m-3 w-100">
                        <ClayCard.Body className="p-4">
                            <ClayLayout.Row className="mb-6">
                              <ClayLayout.Col size={3}>
                                <div className="serial-container">
                                  <ClayInput
                                    type="text"
                                    id={"supportSiteId"}
                                    placeholder={"Support site Id"}
                                    value={searchedSupportSite}
                                    onChange={(event) => {
                                      setIsSearchGoingOn(true);
                                      handleSupportSearch(event.target.value)
                                  }}
                                  />
                                </div>
                              </ClayLayout.Col>
                              <ClayLayout.Col size={3}>
                                <div className="serial-container">
                                  <ClayInput
                                    type="text"
                                    id={"reportId"}
                                    placeholder={"Report Id"}
                                    value={searchedReportId}
                                    onChange={(event) => {
                                      setIsSearchGoingOn(true);
                                      handleReportSearch(event.target.value)
                                  }}
                                  />
                                </div>
                              </ClayLayout.Col>
                              <ClayLayout.Col size={2}>
                                <div className="mt-1 d-flex justify-content-end">
                                  <ClayToggle
                                    label={hardDelete ? "Hard Delete Only" : "Hard & Soft Delete"}
                                    toggled={hardDelete}
                                    onToggle={(toggleValue) => {
                                      toggleChange(toggleValue)
                                    }}
                                    disabled={(searchedReportId.length===0 && searchedSupportSite.length===0)}
                                  />
                                </div>
                              </ClayLayout.Col>
                              <ClayLayout.Col className="d-flex align-items-center flex-row-reverse" size={4}>
                                  <ClayButton 
                                      className="ml-3"
                                      displayType="secondary" 
                                      type="button" 
                                      disabled={Object.values(downloadReportIdObj).length===0}
                                      onClick={()=>{
                                        setModalType("Hard Delete")
                                        setDeleteModalFlag(true)
                                      }}
                                  >
                                      Hard Delete
                                  </ClayButton>
                                  <ClayButton 
                                      className="ml-3"
                                      displayType="secondary" 
                                      type="button" 
                                      onClick={()=>{
                                        setModalType("Soft Delete")
                                        setDeleteModalFlag(true)
                                      }}
                                      disabled={hardDelete || Object.values(downloadReportIdObj).length===0}
                                  >
                                      Soft Delete
                                  </ClayButton>
                              </ClayLayout.Col>
                            </ClayLayout.Row>
                            {showTable &&
                            <Table
                                rowClassName="report-management-logs"
                                headClassName= {"report-management-table-filter"}
                                definitions={REPORT_COLUMNS}
                                handleCheckboxChange={handleCheckboxChange}
                                dataSource={data}
                                borderedColumns={false}
                                borderless={true}
                                headVerticalAlignment={"middle"}
                                headingNoWrap={true}
                                hover={false}
                                noWrap={true}
                                responsive={true}
                                responsiveSize={"sm"}
                                striped={true}
                                tableVerticalAlignment={"middle"}
                                showDeltasDropDown={true}
                                checkDisabledRow={false}
                                showPagination={true}
                            />
                            }
                        </ClayCard.Body>
                    </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
            {
              deleteModalFlag && 
               (
                  <MultiDeleteModal 
                    confirmDelete={handleDelete}
                    reportIdList={Object.values(downloadReportIdObj)}
                    modalClose={() => {
                      document.body.style.overflow = "visible";
                      setModalType("")
                      setDeleteModalFlag(false)
                    }}
                    hardDelete = { modalType === "Hard Delete"}
                    softDelete = { modalType === "Soft Delete"}
                    reportManagement={true}
                  />
                )
            }
        </div>
    )
}

export default SCRTReportManagement;