import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle } from "@common-components";
import { useState } from "react";

const MSNManagemet=()=>{
    let originalItemsObj: any = {};
    const [searchValue, setSearchValue] = useState<string>("");
    const [items, setItems] = useState([] as any);
    
    const handleOnChange = (e: any) => {
        const searchvalue = e.target.value;
        const newItems =
          searchvalue !== ""
            ? [...Object.values(originalItemsObj)].filter((obj: any) =>
                obj.serialNumber.toLowerCase().includes(searchvalue.toLowerCase()),
              )
            : Object.values(originalItemsObj);
    
        setSearchValue(searchvalue);
        setItems([...newItems]);
      };
    return(
        <>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex align-items-center">
                        <PageTitle title={`MSN Management`} />
                    </ClayLayout.Col>
                </ClayLayout.Row>
                <ClayLayout.Row>
                    <ClayCard className="m-3 w-100">

                    </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        </>
    )
}

export default MSNManagemet;