import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { DEFAULT_DELTA, ROUTE_PATHS } from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { GET_USER_EMAIL_AUTHHUB_LOWERCASE } from "@utils";

import { EDIT_SITE_MAPPING_TABS } from "./../../components/screens/siteMapping/constants";
import { MANAGE_URL } from "./constants";

const defaultState = {
  filteredEmailList: [],
  selectedEmail: [],
  emailList: [],
  sendEmailResponse: {},
  userListPagination: {
    activeDelta: DEFAULT_DELTA || 25,
    activePage: 1,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  userList: [],

  customerList: [],
  updateCustomerNumber: "",
  isLoading: true,

  enterpriseList: [],
  billingNonBilling: null,
};

const slice = createSlice({
  name: "manage",
  initialState: { ...defaultState },
  reducers: {
    emailListReceived: (state, action) => {
      if (action.payload) {
        state.productMultiSelect = action.payload;
      }
    },

    filteredEmailListReceived: (state, action) => {
      if (action.payload) {
        state.filteredProductList = action.payload;
      }
    },
    updateSelectedEmail: (state, action) => {
      if (action.payload) {
        state.selectedProducts = action.payload;
      }
    },
    onSendEmailResponse: (state, action) => {
      state.sendEmailResponse = action.payload;
    },
    resetManageState() {
      return { ...defaultState };
    },
    handleUserListPagination: (state, action) => {
      if (action.payload) {
        state.userListPagination = action.payload;
      }
    },
    onErrorEmailList: (state, action) => {
      state.userList = [];
      state.userListPagination = {
        activeDelta: 0,
        activePage: 0,
        ellipsisBuffer: 0,
        totalItems: 0,
      };
    },
    userListData: (state, action) => {
      const scrtUserSites = action.payload?.data?.scrtUserSites || {};
      state.userList = scrtUserSites.content || [];
      state.userListPagination = {
        activeDelta: scrtUserSites.size || DEFAULT_DELTA,
        activePage: scrtUserSites.number + 1 || 1,
        ellipsisBuffer: 5,
        totalItems: scrtUserSites.totalElements || 0,
      };
    },
    updateCustomerList: (state, action) => {
      state.customerList = action.payload?.data?.result || [];
      state.isLoading = false;
    },
    setIsLoadingTrue: (state) => {
      state.isLoading = true;
    },
    onUpdateSiteIdCustomerNumber: (state, action) => {
      state.updateCustomerNumber = action.payload;
    },

    enterpriseListReceived: (state, action) => {
      if (action.payload) {
        state.enterpriseList = action.payload?.data?.result;
      }
    },
    onBillNonBillCheck: (state, action) => {
      if (!action.payload.success || !!action?.payload?.data?.message) {
        state.updateCustomerNumber = {
          data: {
            statusCodeValue: 404,
            body:
              action?.payload?.data?.message ||
              "Unable to process request, Please try after sometime!",
          },
        };
      } else {
        state.billingNonBilling = parseBillingData(action.payload);
      }
    },
  },
});

export const {
  emailListReceived,
  filteredEmailListReceived,
  updateSelectedEmail,
  onSendEmailResponse,
  userListData,
  handleUserListPagination,
  onErrorEmailList,
  updateCustomerList,
  onUpdateSiteIdCustomerNumber,
  setIsLoadingTrue,
  enterpriseListReceived,
  onBillNonBillCheck,
} = slice.actions;

export const getEnterprises = () => (dispatch, state) => {
  return dispatch(
    apiCallBegan({
      url: MANAGE_URL.GET_ENTERPRISES,
      method: "GET",
      onSuccess: enterpriseListReceived.type,
    }),
  );
};

export const sendEmail = (data) => (dispatch, state) => {
  const { URL, body } = getURLWithParamsForSendEmail(data, state);
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "POST",
      data: body,
      onSuccess: onSendEmailResponse.type,
    }),
  );
};

const getURLWithParamsForSendEmail = (data, state) => {
  const URL = MANAGE_URL.SEND_EMAIL;
  if (data?.sendToAll) {
    data.to = [];
  }
  
  const body = {
    from: data?.from || GET_USER_EMAIL_AUTHHUB_LOWERCASE(),
    emailIds: data?.to,
    subject: data?.subject,
    message: data?.message,
    sendToAll: data?.sendToAll || false,
    searchValue: data?.searchValue || "",
    enterprise: data?.enterprise || [""],
    type: data?.type || "broadcast",
    loggedInUser: GET_USER_EMAIL_AUTHHUB_LOWERCASE()
  };
  return { URL, body };
};

export const loadEmailUserList = (searchString, enterpriseList) => (dispatch, getState) => {
  const { URL, body } = getUserListUrl(searchString, enterpriseList, getState());
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "POST",
      data: body,
      onSuccess: userListData.type,
      onError: onErrorEmailList.type,
    }),
  );
};

//To fetch URL, request params and body
//@Input state
//@Output { URL, body }
const getUserListUrl = (searchString, enterpriseList, state) => {
  let URL = MANAGE_URL.GET_USER_LIST;
  const body = {
    searchValue: searchString,
    enterprise: enterpriseList,
  };

  if (state?.manage?.userListPagination) {
    URL += `?size=${state.manage.userListPagination.activeDelta}&page=${
      state.manage.userListPagination.activePage - 1
    }`;
  }

  return { URL, body };
};

//To make respective API call based on Tab selected
export const getCustomerNumbers = (tabName) => (dispatch, state) => {
  const URL =
    tabName === ROUTE_PATHS.AUDIT_MAPPING
      ? MANAGE_URL.AUDIT_CUSTOMER_LIST
      : tabName === EDIT_SITE_MAPPING_TABS.mapExistingCN
      ? MANAGE_URL.GET_EXISTING_CUSTOMER_LIST
      : MANAGE_URL.GET_CUSTOMER_LIST;
  dispatch(setIsLoadingTrue());
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "GET",
      onSuccess: updateCustomerList.type,
    }),
  );
};

export const updateSiteIdByCustomerNumber = (data, tabValue) => (dispatch, state) => {
  const { URL, body } = getUrlandBodyForUpdateSiteIdByCustomerNumber(data, tabValue);
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "POST",
      data: body,
      onSuccess: onUpdateSiteIdCustomerNumber.type,
    }),
  );
};

//To verify Billing and non-Biling Site ID Details
export const checkForBillingNonBillingSiteID = (data) => (dispatch, state) => {
  const body = {
    oldSiteId: data.oldSiteId,
    newSiteId: data.siteId,
  };
  return dispatch(
    apiCallBegan({
      url: MANAGE_URL.CHECK_BILLING_NON_BILL,
      method: "POST",
      data: body,
      onSuccess: onBillNonBillCheck.type,
      onError: onBillNonBillCheck.type,
      skipErrorHandling: true,
    }),
  );
};

const getUrlandBodyForUpdateSiteIdByCustomerNumber = (data, tabValue) => {
  const URL =
    tabValue === ROUTE_PATHS.AUDIT_MAPPING
      ? MANAGE_URL.UPDATE_AUDIT_LIST
      : tabValue === EDIT_SITE_MAPPING_TABS.mapCN
      ? MANAGE_URL.UPDATE_CUSTOMER
      : MANAGE_URL.UPDATE_EXISTING_CUSTOMER_LIST;
  let body = {
    customerNumber: data?.customerNumber,
    siteId: data?.siteId,
  };
  if (tabValue === EDIT_SITE_MAPPING_TABS.mapExistingCN) {
    body["oldSiteId"] = data.oldSiteId;
    body["newSiteId"] = data.siteId;
    delete body["siteId"];
  }
  return { URL, body };
};

export const getSendEmailResponse = (state) => state.manage.sendEmailResponse;
export const getEmailUserList = (state) => state.manage.userList;
export const getEmailUserListPagination = (state) => state.manage.userListPagination;
export const getCustomerList = (state) => state.manage.customerList;
export const getEnterpriseList = (state) => state.manage.enterpriseList;
export const getUpdateCustomerResponse = (state) => state.manage.updateCustomerNumber;
export const getIsLoading = (state) => state.manage.isLoading;
export const getIsBillingNonBilling = (state) => state.manage.billingNonBilling;

export const manageReducer = slice.reducer;

const parseBillingData = (data) => {
  return data.data.billingMap;
};
