import ClayCard from "@clayui/card";
import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import {
  DEFAULT_DELTA,
  ROLE_SUPER_ADMIN,
  ROLE_USER,
  ROUTE_PATHS,
  checkIfInternalUser,
} from "@constants";
import { IRepositoryLogs } from "@interfaces";
import {
  getRepoLogsFilterState,
  getRepoLogsPageState,
  getRepoLogsSortState,
  getRepositoryLogs,
  getRepositoryLogsData,
  handleLogsPaginationState,
  loadRepositoryLogsData,
  parseLogData,
  repositoryLogs,
  resetLogsFilters,
  resetRepositoryLogsState,
} from "@store/repositoryLogs/slice";
import { GET_USER_ROLE, getUserTime } from "@utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ClayButton from "@clayui/button";
import { exportCSVFile, repoLogColumns, timeStampFormat } from "./constants";
import "./repositoryLogsTables.scss";


function RepositoryLogs(props: any) {
  const dispatch = useDispatch();
  const sort = useSelector(getRepoLogsSortState);
  const filterValue = useSelector(getRepoLogsFilterState);
  const paginationInfo = useSelector(getRepoLogsPageState);
  const data = useSelector(getRepositoryLogs);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const rowData = useSelector(getRepositoryLogsData);
  const location = useLocation();
  const isRepoLogs = location.pathname === ROUTE_PATHS.REPO_LOGS;
  const isAuditLogs = location.pathname === ROUTE_PATHS.AUDIT_LOGS;
  const [active,setActive] = useState<boolean>(false);
  
  //Check if Valid user
  useEffect(() => {
    //If Path is repo-logs no need for user details
    if (location.pathname !== ROUTE_PATHS.REPO_LOGS) {
      if (isOktaEnabled && isValidUser !== "true") {
        window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
      } else if (
        GET_USER_ROLE() === ROLE_USER ||
        (location.pathname === ROUTE_PATHS.AUDIT_LOGS && GET_USER_ROLE() !== ROLE_SUPER_ADMIN)
      ) {
        window.location.href = ROUTE_PATHS.NO_ACCESS;
      }
    } else {
      //If path is repo-logs and scrtInternal = true redirect back to OktaRedirect page
      if (!checkIfInternalUser()) {
        window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
      }
    }
    //Resetting State For Repo Details
    return () => {
      dispatch(resetRepositoryLogsState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newData: IRepositoryLogs[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IRepositoryLogs) => ({
          ...row,
        }))) ||
      [];
    newData = parseLogData(
      [...newData],
      "erpcustomername",
      "erpcustomernumber",
      "erpcustomername",
      "||",
    );
    newData = parseLogData([...newData], "username", "userId", "username");
    dispatch(repositoryLogs(newData));
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData?.totalElements || 0,
    };
    dispatch(handleLogsPaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetLogsFilters());
    }
  };

  const getRepoLogDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(loadRepositoryLogsData(sortState, filter, pageInfo, isRepoLogs, isAuditLogs));
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getRepoLogDetails(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(loadRepositoryLogsData(val, null, newPagination, isRepoLogs, isAuditLogs));
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getRepoLogDetails(null, null, newPagination);
  };

  const downloadCSVReport=()=>{
    let newList:any[]=[...data]
    let updatedList:any[]=[]
    let obj:any={}
    newList.forEach((data:any)=>{
      if (isAuditLogs) {
        obj={
          "date":data.date ? timeStampFormat(data.date).replaceAll(",", "") : " ",
          "status":data.status ? data.status.replaceAll(",", "") : " ",
          "reportType":data.reportType? data.reportType.replaceAll(",", "") :" " ,
          "source":data.source ? data.source.replaceAll(",", ""):" ",
          "supportSiteId" :data.supportSiteId ? data.supportSiteId.replaceAll(",", ""):" ",
          "username":data.username ? data.username.replaceAll(",", "") :" ",
          "erpcustomername":data.erpcustomername ? data.erpcustomername.replaceAll(",", "") : " ",
          "filename":data.filename ? data.filename.replaceAll(",", "") : " ",
          "concatenate":data.concatenate ? data.concatenate.replaceAll(",", "") : " ",
          "serialnumber":data.serialnumber ? data.serialnumber.replaceAll(",", "") : " ",
          "reportingPeriod":data.reportingPeriod ? data.reportingPeriod.replaceAll(",", " "): " ", //.replaceAll(",", "") : "", 
          "errorMessage":data.errorMessage ? data.errorMessage.replaceAll(",", "").replaceAll("<b>", "").replaceAll("</b>").replaceAll("<b/>", "") : " ",
        } 
      } else{
        obj={
          "date":data.date ? timeStampFormat(data.date).replaceAll(",", "") : " ",
          "status":data.status ? data.status.replaceAll(",", "") : " ",
          "reportType":data.reportType? data.reportType.replaceAll(",", "") : " " ,
          "source":data.source ? data.source.replaceAll(",", "") : " ",
          "username":data.username ? data.username.replaceAll(",", "") : " ",
          "erpcustomername":data.erpcustomername ? data.erpcustomername.replaceAll(",", "") : " ",
          "filename":data.filename ? data.filename.replaceAll(",", "") : " ",
          "concatenate":data.concatenate ? data.concatenate.replaceAll(",", "") : " ",
          "serialnumber":data.serialnumber ? data.serialnumber.replaceAll(",", "") : " ",
          "reportingPeriod":data.reportingPeriod ? data.reportingPeriod.replaceAll(",", " ") : " ", 
          "errorMessage":data.errorMessage ? data.errorMessage.replaceAll(",", "").replaceAll("<b>", "").replaceAll("</b>").replaceAll("<b/>", ""): "",
        }

      }
      updatedList.push(obj)
    })
    let columnList:any[]=[...repoLogColumns(handleResetFilter, isAuditLogs)]
    let headerObj:any={}
    columnList.map((header:any)=>{
      if(header.field !== "action"){
        headerObj[header.field] = header.headerName
      }
    })

    let fileType:string= isAuditLogs ? "Audit_Repository_Logs" : "Repository Logs"
    let fileName:string = `SCRT_${fileType}_${Date.now()}`
    let finalList:any[]=[headerObj,...updatedList]
    //exportExcel(finalList,fileName)
    exportCSVFile(finalList,fileName)
  }

  const onBack = () => {
    window.location.href = ROUTE_PATHS.AUDIT;
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row>{isRepoLogs && <PageTitle title={"Repository Logs"} />}</ClayLayout.Row>
        {isAuditLogs && (
          <ClayLayout.Row justify="start" className="align-items-center">
            <ClayLayout.Col className="d-flex align-items-center">
              <ClayIcon
                symbol="order-arrow-left"
                className="pe-cursor"
                fontSize={"30px"}
                spritemap={claySpriteImagePath()}
                onClick={onBack}
              />
              &emsp;
              <PageTitle title={"Audit Repository Logs"} />
            </ClayLayout.Col>
          </ClayLayout.Row>
        )}

        <ClayLayout.Row >
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <Table
                headClassName="repo-log-table-filter"
                definitions={repoLogColumns(handleResetFilter, isAuditLogs)}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
              />
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default RepositoryLogs;
