import { axiosApi } from "@common-services/redux-helper/middleware/axios-api";
import { showAlert } from "@common-services/redux-helper/store/slice/globalAlertHandler.slice";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import {SCRT_REPORT_MANAGEMENT_URL} from "./constant"

const slice = createSlice({
  name: "scrtReportManagement",
  initialState: {
    scrtRepoList:[],
    successFullDelete:false
  },
  reducers: {
    setSCRTRepoList: (scrtReportManagement,action) => {
      let updatedList=[]
        if(action.payload.success && action.payload.data){
            action.payload.data.result.forEach((data)=>{
              let obj={}
              obj={...data,uploadDate:data.uploadDate.slice(0,10),reportingPeriodStartDateEpoch:data.uploadDate.slice(0,10)}
              updatedList.push(obj)
            })
            
            scrtReportManagement.scrtRepoList = updatedList;
            scrtReportManagement.successFullDelete=false;
        }
    },
    setSuccessFullDelete: (scrtReportManagement,action) => {
        if(action.payload.success){
            scrtReportManagement.successFullDelete=action.payload.success;
        }
    },
  },
});

export const {
  setSCRTRepoList,
  setSuccessFullDelete
} = slice.actions;

export const loadSCRTReportManagementDetails = ( body ) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: SCRT_REPORT_MANAGEMENT_URL.GET_ALL_REPORTS,
        method: "POST",
        data: body,
        onSuccess: setSCRTRepoList.type,
        onError: setSCRTRepoList.type,
      }),
    );
};

export const deleteReports = ( body ) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: SCRT_REPORT_MANAGEMENT_URL.DELETE_REPORTS,
        method: "POST",
        data: body,
        onSuccess: setSuccessFullDelete.type,
        onError: setSuccessFullDelete.type,
      }),
    );
};

export const store = configureStore({
  reducer: slice.reducer,
});

export const getSCRTRepoList = (state) => state.scrtReportManagement.scrtRepoList;
export const getSuccessFullDelete = (state) => state.scrtReportManagement.successFullDelete;

// reducer
export const scrtReportManagementReducer = slice.reducer;
