import { IconButton } from "@common-components";

import { DownloadComponent } from "./DownloadComponent";

const ActionComponent = (props: any) => {
  const {
    rowData,
    preview,
    showDelete,
    deleteRow,
    hidePreview = false,
    disabledDownload,
    source,
    handleClose = "",
    showEdit,
    editUser
  } = props;
  if (rowData?.length) {
    return <></>;
  }
  return (
    <div className="d-flex">
      {!hidePreview && (
        <IconButton
          show={true}
          title="Preview Details"
          symbol={"view"}
          className="scrt-repo-action-button"
          onClick={() => preview(rowData)}
        ></IconButton>
      )}
      {(!disabledDownload || rowData?.reportId || rowData?.crushftpfilename) && (
        <DownloadComponent
          reportIdList={rowData?.reportId ? [parseInt(rowData?.reportId)] : []}
          fileName={rowData?.crushFtpFilename || rowData?.crushftpfilename}
          source={rowData?.sourceLocation || source}
          handleClose={handleClose}
        />
      )}
      {showEdit && (
        <IconButton
          show={true}
          title="Edit Details"
          symbol="credit-card"
          className="scrt-repo-action-button"
          onClick={() => editUser(rowData)}
          hidden={false}
        ></IconButton>
      )}
      {showDelete && (
        <IconButton
          show={true}
          title="Delete User"
          symbol="trash"
          className="scrt-repo-action-button"
          onClick={() => deleteRow(rowData)}
          hidden={rowData?.canDelete === "N"}
        ></IconButton>
      )}
    </div>
  );
};
export { ActionComponent };
