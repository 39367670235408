
export const CONSOLE_TABS = {
    userManagement: "Broadcom SCRT User Management",
    msnManagemet: "MSN Management",
    scrtReportManagement: "SCRT Report Management",
    reminderManagement: "Reminder Management",
    customerNumberMapping: "Customer Number Mapping",
    broadcastMessage:"Broadcast Message",
    productCatalogManagement:"Product Catalog Management"
  };
  
  export const CONSOLE_TABS_LIST = [
    {
      display: "Broadcom SCRT User Management",
      show: true,
      navPath: CONSOLE_TABS.userManagement,
    },
    // {
    //   display: "MSN Management",
    //   show: true,
    //   navPath: CONSOLE_TABS.msnManagemet,
    // },
    {
      display: "SCRT Report Management",
      show: true,
      navPath: CONSOLE_TABS.scrtReportManagement,
    },
    {
        display: "Reminder Management",
        show: true,
        navPath: CONSOLE_TABS.reminderManagement,
    },
    // {
    //     display: "Customer Number Mapping",
    //     show: true,
    //     navPath: CONSOLE_TABS.customerNumberMapping,
    // },
    // {
    //     display: "Broadcast Message",
    //     show: true,
    //     navPath: CONSOLE_TABS.broadcastMessage,
    // },
    // {
    //     display: "Product Catalog Management",  
    //     show: true,
    //     navPath: CONSOLE_TABS.productCatalogManagement,
    // },
  ];
  
